<template>
  <div
    :class="[
      'main-block',
      {
        _open: isOpen,
      },
    ]"
  >
    <div class="_desctop">
      <h2
        class="main-block__title"
        v-html="i18n.title"
      />
      <img
        class="main-block__img"
        src="./girl.png"
        alt="счастливый человек с деньгами в руках"
      >
    </div>

    <div class="_mobile">
      <div class="main-block__img">
        <img
          src="./mobile-img.svg"
          alt="0%"
        >
      </div>
      <h2
        class="main-block__title"
        v-html="i18n.titleMobile"
      />
    </div>
    <Calculator class="main-block__calculator" />
  </div>
</template>

<style lang="css">
.main-block {
  height: 714px;
  position: relative;
  overflow: hidden;
}

.main-block__title {
  margin: 2.5rem 1.5rem 1rem;
  font-size: 32px;
  line-height: 115%;
}

.main-block__img {
  position: absolute;
  float: right;
  width: 100%;
  object-fit: cover;
  background: radial-gradient(49.88% 90.88% at 50.29% 96.73%, #fea109 42%, #fefbcc 80.08%, #ffffff 100%);
}

.main-block__calculator {
  position: absolute;
  bottom: 0;
}

.main-block ._mobile {
  display: none;
}

@media (max-width: 767px) {
  .main-block._open {
    background: #ffd600;
  }

  .main-block._open.main-block {
    height: auto;
    position: static;
    overflow: initial;
  }

  .main-block__calculator {
    box-shadow: none;
  }

  .main-block._open .main-block__calculator {
    position: static;
  }

  .main-block ._desctop {
    display: none;
  }

  .main-block {
    height: 453px;
  }

  .main-block ._mobile {
    position: relative;
    padding: 24px 20px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  .main-block._open ._mobile {
    display: none;
  }

  ._mobile .main-block__title {
    margin: 0;
    padding-left: 27px;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 400;
  }

  ._mobile .main-block__img {
    position: static;
    float: none;
    max-width: 100%;
    width: 136px;
    height: 83px;
    flex: none;
    display: flex;
    background: none;
  }

  ._mobile::after {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: -1;
    width: 100vw;
    height: calc(100vw * (97 / 80));
    display: inline-block;
    transform: translate(0, -10%);
    background: url("./bg-gradient.png") center no-repeat;
    background-size: contain;
    content: "";
  }
}

@media (min-width: 768px) {
  .landing-content.main-block {
    height: 570px;
    margin-top: 1.5rem;
  }

  .main-block__title {
    position: relative;
    margin: 2.5rem 1.5rem;
    margin-bottom: 32px;
    z-index: 1;
    font-size: 48px;
    line-height: 115%;
  }

  .main-block__img {
    width: auto;
    top: 0;
    right: 0;
    z-index: 0;
    margin-right: 2rem;
  }

  .main-block__calculator {
    position: relative;
  }
}
</style>

<script>
import Calculator from "../calculator/calculator.vue";

export default {
  name: "MainBlock",
  components: {
    Calculator,
  },
  props: {
    i18n: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
    },
  },
};
</script>
